<template>
  <div class="min-h-screen flex relative lg:static bg-surface-50 dark:bg-surface-950">
    <LayoutDefaultSideBarComponent />
    <div class="h-screen w-full max-h-screen min-h-screen flex flex-col relative flex-auto overflow-hidden">
      <LayoutDefaultTopBarComponent />
      <div class="flex flex-col flex-auto overflow-hidden">
        <LayoutDefaultBreadCrumbComponent />
        <div class="border border-surface bg-surface-0 dark:bg-surface-950 flex-auto inline-flex overflow-auto">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
